<template>
	<div class="divProgressBar mx-auto">
		<v-progress-linear
			:value="0"
			height="10"
			:class="{ 'mt30 mb70': $vuetify.breakpoint.mdAndUp, 'mt-10 mb-8': $vuetify.breakpoint.smAndDown }"
			rounded
			buffer-value="95"
			stream
			background-color="#F1F1F1"
		></v-progress-linear>
		<div class="steps beginnerStep text-center">
			<span
				:class="{ fs15: $vuetify.breakpoint.mdAndUp, fs10: $vuetify.breakpoint.smAndDown }"
				style="color: transparent"
				>.</span
			>
			<div class="circle"></div>
		</div>
		<div
			v-for="(element, index) in mockElements"
			:key="index"
			class="steps text-center"
			:style="{ left: element.left }"
		>
			<span
				class="plainOpacityStatus"
				:class="{ fs15: $vuetify.breakpoint.mdAndUp, fs10: $vuetify.breakpoint.smAndDown }"
				>{{ element.name }}</span
			>
			<div class="circle mx-auto mb-2" v-if="index != mockElements.length - 1"></div>
			<span
				class="ortho-text-gray plainOpacityStatus"
				:class="{ fs15: $vuetify.breakpoint.mdAndUp, fs10: $vuetify.breakpoint.smAndDown }"
				v-if="index != mockElements.length - 1"
			>
				<v-skeleton-loader type="text"></v-skeleton-loader>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProgressionBarSkeleton',
	data() {
		return {
			mockElements: [
				{ name: 'Motivé', left: '15%' },
				{ name: 'Confirmé', left: '50%' },
				{ name: 'Avancé', left: '75%' },
				{ name: 'Maître', left: '99%' },
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.divProgressBar {
	position: relative;
	.steps {
		position: absolute;
		top: -1.8rem;
		z-index: 0;
		.circle {
			width: 1.2rem;
			height: 1.2rem;
			border-radius: 100%;
			border: 1px solid #f1f1f1;
			background-color: #f1f1f1;
		}
	}
}

.plainOpacityStatus {
	opacity: 0.2;
}

@media screen and (max-width: 600px) {
	.divProgressBar {
		.steps {
			position: absolute;
			top: -1.7rem;
			z-index: 0;
			.circle {
				width: 1rem;
				height: 1rem;
				border-radius: 100%;
				border: 1px solid #f1f1f1;
				background-color: #f1f1f1;
			}
		}
	}
}
</style>
